<template>
  <NuxtLink to="contact" class="block xl:ml-[280px] lg:ml-[210px] md:ml-[82px] max-w-[380px] xl:pb-[60px] lg:pb-[44px] pb-[53px]">
    <div class="hidden md:block">
      <div class="flex items-center">
        <h2>Work</h2>
        <p class="p-medium max-w-[172px] ml-6">
          Let’s make something memorable together.
        </p>
      </div>
      <div>
        <h2>with us</h2>
      </div>
    </div>
    <div class="block md:hidden">
      <h4 class="mb-2">Work with us</h4>
      <p class="p-small max-w-[200px]">
        Let’s make something memorable together.
      </p>
    </div>
  </NuxtLink>
</template>

<script lang="ts">
export default {
  name: "WorkWithUs",
};
</script>
